import React from 'react'
import Layout from '../components/layout'
import { graphql} from 'gatsby'
import AgendaItem from '../components/AgendaItem/agendaItemView'
import { GatsbyImage } from 'gatsby-plugin-image'





const EvenementenIndex = (props) => {

  const events = props.data.allContentfulEventPage.nodes[0].evenementenData;
  const flyers = props.data.allContentfulEventPage.nodes[0].flyers;
  const half = Math.ceil(events.length / 2);  
  const firstHalf = events.slice(0, half);
  const secondHalf = events.slice(half);





    return (
      <Layout title={props.data.allContentfulEventPage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>{props.data.allContentfulEventPage.nodes[0].title}</h1>
        <div className='contact_form_container'>
          <h2>Agenda</h2>
          <div className='row full_width'>
            <div className='column half_width' style={{alignItems: 'center'}}>
              {(firstHalf).map((event) => <AgendaItem date={event.eventDatum} slug={event.slug} heading={event.eventName}/> )  }
            </div>
            <div className='column half_width' style={{alignItems: 'center'}}>
              {(secondHalf).map((event) => <AgendaItem date={event.eventDatum} slug={event.slug} heading={event.eventName}/> )  }

            </div>
          </div>
        </div>

        <div style={{height: '100px'}}></div>


        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
        {/* TODO: Add flyers */}
          {(flyers).map((flyer) => <GatsbyImage style={{marginBottom: '50px'}} alt='' image={flyer.image?.gatsbyImageData}/> )  }




        </div>

        


      </Layout>

    )
  }


export default EvenementenIndex


export const PageQuery = graphql`
query EventQuery {
  allContentfulEventPage {
    nodes {
      title
      evenementenData {
        eventName
        eventDatum
        slug
      }
      flyers {
        image {
          gatsbyImageData(width: 600)
        }
      }
    }
  }
}

`






