import React from 'react'
import { Link } from 'gatsby'



const AgendaItem = ({ heading, date, slug  }) => (
    <div style={{margin: '20px'}}>
            <Link to={`/evenementen/${slug}`} className="link">
            <h3 style={{margin: '0px'}}>{date}</h3>
            <p style={{margin: '0px'}}>{heading}</p>
            </Link>
    </div>
)

export default AgendaItem